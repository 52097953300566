import { createTheme } from '@mui/material/styles';

const primaryColor = '#9AF722';
const secondaryColor = '#8837FE';
const thirdColor = '#8837FE';
const backgroundColor = '#050B14';
const backgroundDarkColor = '#171717';
const grayColor = '#747474';
const whiteColor = '#FFFFFF';
const textColor = '#E0E0E0';
const textSecondaryColor = '#C0C0C0';
const tableCellBorderColor = '#1A1A1A';
const inputBackgroundColor = '#1E1E1E'; // A color closer to the background


const theme = createTheme({
  palette: {
    mode: 'light',
    action: {
      disabledBackground: 'rgba(0, 0, 0, 0.06)',
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    common: {
      black: '#000000',
      white: '#ffffff',
    },
    divider: '#dcdfe4',
    error: {
      main: '#f04438',
      light: '#f97970',
      dark: '#de3024',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#fb9c0c',
      light: '#ffbb1f',
      dark: '#de7101',
      contrastText: '#ffffff',
    },
    info: {
      main: '#04aad6',
      light: '#10bee8',
      dark: '#0787b3',
      contrastText: '#ffffff',
    },
    success: {
      main: '#15b79f',
      light: '#2ed3b8',
      dark: '#0e9382',
      contrastText: '#ffffff',
    },
    primary: {
      main: '#8837FE',
      light: '#9AF722',
      dark: '#050B14',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#32383e',
      light: '#555e68',
      dark: '#202427',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#212636',
      secondary: '#667085',
      disabled: '#8a94a6',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
  },
  typography: {
    fontFamily: "'Chopin', sans-serif",
    body1: {
      fontSize: '15px',
      lineHeight: 1.75,
      fontWeight: 400,
    },
    h1: {
      fontSize: '22px',
      fontWeight: 700,
      '&::after': {
        content: '""',
        borderRadius: '25px',
        display: 'block',
        height: '5px',
        marginTop: '9px',
        width: '30px',
      },
    },
    h6: {
      fontSize: '16px',
      fontWeight: 800,
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: `1px solid ${thirdColor}`,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '16px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
            },
            '&:hover fieldset': {
            },
            '&.Mui-focused fieldset': {
            },
            '& input': {
            },
          },
          '& .MuiFormLabel-root': {
            '&.Mui-focused': {
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-select': {
            '&:focus': {
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
          },
          '&:hover fieldset': {
          },
          '&.Mui-focused fieldset': {
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
        },
        body: {
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '0.6em 1em',
          borderRadius: '5px',
          fontSize: '14px',
          transition: 'all 0.5s ease-in-out',
          '&:hover': {
          },
        },
        containedSecondary: {
          '&:hover': {
          },
        },
        outlinedSecondary: {
          '&:hover': {
          },
        },
        textSecondary: {
          '&:hover': {
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '20px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: primaryColor,
          borderBottom: `4px solid ${primaryColor}`,
          borderRight: `4px solid ${primaryColor}`,
          borderLeft: `1px solid ${primaryColor}`,
          borderRadius: '50%',
          maxHeight: '170px',
          maxWidth: '170px',
          width: 'auto',
          height: 'auto',
          alignSelf: 'center',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h3: {
          lineHeight: 'normal',
          margin: '30px 0 0 0',
          fontWeight: 700,
          fontSize: '24px',
        },
        subtitle1: {
          fontSize: '12px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          outline: 0,
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderRadius: '25px',
          height: '1px',
          margin: '45px auto',
          width: '100%',
        },
      },
    },
    // Additional components styling
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          '& .MuiChip-label': {
          },
        },
        deleteIcon: {
          '&:hover': {
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          '& .MuiSnackbarContent-action': {
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '13px',
          borderRadius: '4px',
        },
        arrow: {
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
          padding: '20px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
          },
        },
      },
    },
  },
});

export default theme;
