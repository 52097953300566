export const formatDate = (date: string | null) => {
    if (!date) return 'N/A';
    const datePattern = /^(\d{2})\/(\d{2})\/(\d{2}|\d{4})$/;
    if (datePattern.test(date)) {
      return date;
    }
    const d = new Date(date);
    return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
  };
  
  export const formatCurrency = (value: number) => {
    return value === 0 ? 'N/A' : new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };
  