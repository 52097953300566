import React, { useEffect, useState } from 'react';
import {
  Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel,
  MenuItem, Select, SelectChangeEvent, Slider, TextField, Typography
} from '@mui/material';
import { formatCurrency } from '../utils/format';

interface SearchFormProps {
  onSearch: (filters: any) => void;
  onReset: () => void;
  dropdownOptions: {
    segmento: string[];
    estado: string[];
    cidade: string[];
    agencia: string[];
  } | null;
  minMaxValues: {
    max_value_combo_stories: number;
    max_value_insert_youtube: number;
    max_value_reels: number;
    max_value_tiktok: number;
    min_value_combo_stories: number;
    min_value_insert_youtube: number;
    min_value_reels: number;
    min_value_tiktok: number;
  } | null;
  handleToggleFilters: () => void;
}

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, onReset, dropdownOptions, minMaxValues, handleToggleFilters }) => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedSegments, setSelectedSegments] = useState<string[]>([]);
  const [platforms, setPlatforms] = useState<{ instagram: boolean; tiktok: boolean; youtube: boolean }>({
    instagram: true,
    tiktok: true,
    youtube: true,
  });
  const [followerBase, setFollowerBase] = useState({
    instagram: [10000, 500000],
    tiktok: [5000, 300000],
    youtube: [1000, 100000],
  });
  const [location, setLocation] = useState({ state: '', city: '' });
  const [agency, setAgency] = useState('');
  const [financialValues, setFinancialValues] = useState({
    reels: [0, 0],
    stories: [0, 0],
    youtubeInsertions: [0, 0],
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const handlePlatformChange = (platform: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlatforms({ ...platforms, [platform]: event.target.checked });
  };

  const handleSliderChange = (name: string) => (event: Event, newValue: number | number[]) => {
    setFollowerBase({ ...followerBase, [name]: newValue });
  };

  const handleFinancialSliderChange = (name: string) => (event: Event, newValue: number | number[]) => {
    setFinancialValues({ ...financialValues, [name]: newValue });
  };

  const handleSegmentChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedSegments(event.target.value as string[]);
  };

  const handleLocationChange = (field: string) => (event: SelectChangeEvent<string>) => {
    setLocation({ ...location, [field]: event.target.value as string });
  };

  const handleAgencyChange = (event: SelectChangeEvent<string>) => {
    setAgency(event.target.value as string);
  };

  const handleSearch = () => {
    let filters: Record<string, any> = {};

    if (searchKeyword) filters.nome = searchKeyword;
    if (selectedSegments.length > 0) filters.segmento = selectedSegments.join(',');

    filters.instagram = platforms.instagram ? 'true' : 'false';
    filters.tiktok = platforms.tiktok ? 'true' : 'false';
    filters.youtube = platforms.youtube ? 'true' : 'false';

    if (followerBase.instagram[0] !== 10000 || followerBase.instagram[1] !== 500000) {
      filters.base_insta = `${followerBase.instagram[0]}-${followerBase.instagram[1]}`;
    }

    if (followerBase.tiktok[0] !== 5000 || followerBase.tiktok[1] !== 300000) {
      filters.base_tiktok = `${followerBase.tiktok[0]}-${followerBase.tiktok[1]}`;
    }

    if (followerBase.youtube[0] !== 1000 || followerBase.youtube[1] !== 100000) {
      filters.base_youtube = `${followerBase.youtube[0]}-${followerBase.youtube[1]}`;
    }

    if (location.state) filters.estado = location.state;
    if (location.city) filters.cidade = location.city;
    if (agency) filters.agencia = agency;

    if (financialValues.reels[0] !== minMaxValues?.min_value_reels || financialValues.reels[1] !== minMaxValues?.max_value_reels) {
      filters.min_value_reels = financialValues.reels[0];
      filters.max_value_reels = financialValues.reels[1];
    }

    if (financialValues.stories[0] !== minMaxValues?.min_value_combo_stories || financialValues.stories[1] !== minMaxValues?.max_value_combo_stories) {
      filters.min_value_combo_stories = financialValues.stories[0];
      filters.max_value_combo_stories = financialValues.stories[1];
    }

    if (financialValues.youtubeInsertions[0] !== minMaxValues?.min_value_insert_youtube || financialValues.youtubeInsertions[1] !== minMaxValues?.max_value_insert_youtube) {
      filters.min_value_insert_youtube = financialValues.youtubeInsertions[0];
      filters.max_value_insert_youtube = financialValues.youtubeInsertions[1];
    }

    onSearch(filters);
  };
  useEffect(() => {
    if (minMaxValues) {
      setFinancialValues({
        reels: [minMaxValues.min_value_reels, minMaxValues.max_value_reels],
        stories: [minMaxValues.min_value_combo_stories, minMaxValues.max_value_combo_stories],
        youtubeInsertions: [minMaxValues.min_value_insert_youtube, minMaxValues.max_value_insert_youtube],
      });
    }
  }, [minMaxValues]);
  
  const handleReset = () => {
    setSearchKeyword('');
    setSelectedSegments([]);
    setPlatforms({ instagram: true, tiktok: true, youtube: true });
    setFollowerBase({ instagram: [10000, 500000], tiktok: [5000, 300000], youtube: [1000, 100000] });
    setLocation({ state: '', city: '' });
    setAgency('');
    if (minMaxValues) {
      setFinancialValues({
        reels: [minMaxValues.min_value_reels, minMaxValues.max_value_reels],
        stories: [minMaxValues.min_value_combo_stories, minMaxValues.max_value_combo_stories],
        youtubeInsertions: [minMaxValues.min_value_insert_youtube, minMaxValues.max_value_insert_youtube],
      });
    }
    onReset();
  };

  return (
    <Box>
      <Typography variant="h6">Nome do influencer</Typography>
      <TextField
        fullWidth
        placeholder="Digite o nome do influencer ..."
        value={searchKeyword}
        onChange={handleSearchChange}
        margin="normal"
      />

      <Grid container spacing={4}>
        {/* Column 1: Search and Segments */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6" sx={{ mt: 1 }}>Segmentos</Typography>
          <FormControl fullWidth margin="normal">
            <Select
              multiple
              value={selectedSegments}
              onChange={handleSegmentChange}
              renderValue={(selected) => (selected as string[]).join(', ')}
            >
              <MenuItem value="">
                Selecione
              </MenuItem>
              {dropdownOptions?.segmento?.map((segment: string) => (
                <MenuItem key={segment} value={segment}>
                  {segment}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography variant="h6" sx={{ mt: 1 }}>Agency</Typography>
          <FormControl fullWidth margin="normal">
            <Select
              value={agency}
              onChange={handleAgencyChange}
            >
              <MenuItem value="">
                Selecione
              </MenuItem>
              {dropdownOptions?.agencia?.map((agencyOption: string) => (
                <MenuItem key={agencyOption} value={agencyOption}>
                  {agencyOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography variant="h6" sx={{ mt: 1 }}>Localização</Typography>
          <Grid container spacing={2} sx={{mt:1}} >
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Select
                  value={location.state}
                  onChange={handleLocationChange('state')}
                >
                  <MenuItem value="">
                    Selecione
                  </MenuItem>
                  {dropdownOptions?.estado?.map((state: string) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Select
                  value={location.city}
                  onChange={handleLocationChange('city')}
                >
                  <MenuItem value="">
                    Selecione
                  </MenuItem>
                  {dropdownOptions?.cidade?.map((city: string) => (
                    <MenuItem key={city} value={city}>
                      {city}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
{/* Column 2: Platforms and Follower Base */}
<Grid item xs={12} md={4}>
          <Typography variant="h6" sx={{ mt: 1 }}>Base de seguidores</Typography>
          <Box sx={{ mt: 2 }}>
            <Typography>Instagram: {followerBase.instagram.join(' - ')}</Typography>
            <Slider
              size="small"
              value={followerBase.instagram}
              onChange={handleSliderChange('instagram')}
              min={10000}
              max={500000}
              valueLabelDisplay="auto"
              sx={{ mb: 2 }}
            />
            <Typography>TikTok: {followerBase.tiktok.join(' - ')}</Typography>
            <Slider
              size="small"
              value={followerBase.tiktok}
              onChange={handleSliderChange('tiktok')}
              min={5000}
              max={300000}
              valueLabelDisplay="auto"
              sx={{ mb: 2 }}
            />
            <Typography>YouTube: {followerBase.youtube.join(' - ')}</Typography>
            <Slider
              size="small"
              value={followerBase.youtube}
              onChange={handleSliderChange('youtube')}
              min={1000}
              max={100000}
              valueLabelDisplay="auto"
            />
          </Box>

          <Typography variant="h6">Plataformas</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={platforms.instagram}
                  onChange={handlePlatformChange('instagram')}
                />
              }
              label="Instagram"
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={platforms.tiktok}
                  onChange={handlePlatformChange('tiktok')}
                />
              }
              label="TikTok"
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={platforms.youtube}
                  onChange={handlePlatformChange('youtube')}
                />
              }
              label="YouTube"
            />
          </Box>
        </Grid>
         {/* Column 3: Financial Values */}
         <Grid item xs={12} md={4}>
          <Typography variant="h6" sx={{ mt: 1 }}>Valores</Typography>
          {minMaxValues && (
            <Box sx={{ mt: 2 }}>
              <Typography>Reels: {`${formatCurrency(financialValues.reels[0])} - ${formatCurrency(financialValues.reels[1])}`}</Typography>
              <Slider
                size="small"
                value={financialValues.reels}
                onChange={handleFinancialSliderChange('reels')}
                min={minMaxValues.min_value_reels}
                max={minMaxValues.max_value_reels}
                valueLabelDisplay="auto"
                sx={{ mb: 2 }}
              />
              <Typography>Stories: {`${formatCurrency(financialValues.stories[0])} - ${formatCurrency(financialValues.stories[1])}`}</Typography>
              <Slider
                size="small"
                value={financialValues.stories}
                onChange={handleFinancialSliderChange('stories')}
                min={minMaxValues.min_value_combo_stories}
                max={minMaxValues.max_value_combo_stories}
                valueLabelDisplay="auto"
                sx={{ mb: 2 }}
              />
              <Typography>YouTube Insertions: {`${formatCurrency(financialValues.youtubeInsertions[0])} - ${formatCurrency(financialValues.youtubeInsertions[1])}`}</Typography>
              <Slider
                size="small"
                value={financialValues.youtubeInsertions}
                onChange={handleFinancialSliderChange('youtubeInsertions')}
                min={minMaxValues.min_value_insert_youtube}
                max={minMaxValues.max_value_insert_youtube}
                valueLabelDisplay="auto"
              />
            </Box>
          )}
        </Grid>

        {/* Search and Reset Buttons */}
        <Grid item xs={12}>
<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
<Button
        variant="contained"
        color="primary"
        onClick={handleToggleFilters}
        sx={{  mb: 2 }}
      >
        Filtros Adicionais
      </Button>
      <Box sx={{display: 'flex',gap: 3,  mb: 2}}>
            
            <Button variant="outlined" color="secondary" onClick={handleReset}>
              Limpar Filtros
            </Button>
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Buscar Influencers
            </Button>
          </Box>
</Box>
         
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchForm;