import axios from 'axios';
import { DataRow } from '../utils/constants';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch all influencers data
export const fetchData = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/influencers`);
    return response.data;
  } catch (error) {
    console.error('There was an error fetching the data!', error);
    return [];
  }
};

// Fetch dropdown options for filters
export const fetchDropdownOptions = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/influencers/dropdown-options`);
    return response.data;
  } catch (error) {
    console.error('Error fetching dropdown options:', error);
    return null;
  }
};

// Fetch min/max values for filtering
export const fetchMinMaxOptions = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/influencers/minmax-values`);
    return response.data;
  } catch (error) {
    console.error('Error fetching min/max values:', error);
    return null;
  }
};

// Search influencers based on filters
export const searchInfluencers = async (filters: any) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/influencers/search`, {
      params: filters, // Pass filters as query parameters
    });
    return response.data;
  } catch (error) {
    console.error('Error searching influencers:', error);
    return [];
  }
};

// Update influencer data by ID
export const updateInfluencer = async (id: string, influencerData: DataRow) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/influencers/${id}`, influencerData);
    return response.data;
  } catch (error) {
    console.error(`Error updating influencer with ID: ${id}`, error);
    return null;
  }
};

export const deleteInfluencer = async (id: string) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/influencers/${id}`);
    if (response.status === 204) {
      return true; // Success
    }
    return false; // Handle unexpected response statuses
  } catch (error) {
    console.error(`Error deleting influencer with ID: ${id}`, error);
    return false; // Failure
  }
};
