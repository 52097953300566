import React, { useState, useEffect } from 'react';
import { CssBaseline, Box, Toolbar, Button } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SidebarMenu from './SidebarMenu'; // Import the SidebarMenu component
import Home from '../pages/Home';
import Logo from '../assets/images/logo_green.png';
import Estimates from '../pages/Estimates';
import NewEstimate from '../pages/NewEstimate';

const drawerWidth = 240;

const Dashboard: React.FC = () => {
  useEffect(() => {
    document.title = 'Dashboard - Lub'; // Set title when this component is mounted
  }, []);

  const handleLogout = () => {
    // Perform logout action here
    console.log('Logging out...');
  };

  return (
    <Router>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        {/* Sidebar Menu */}
        <Box
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'primary.dark',
            color: 'white',
            position: 'fixed',
            height: '100vh',
          }}
        >
          <Box sx={{ padding: '16px', display: 'flex', alignItems: 'center' }}>
            <img src={Logo} alt="Logo" style={{ height: 40, marginRight: 16 }} />
          </Box>
          <SidebarMenu /> {/* Insert the SidebarMenu component */}
        </Box>

        {/* Main Content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: 'background.default',
            minHeight: '100vh',
            marginLeft: `${drawerWidth}px`, // Add margin to account for the fixed sidebar
          }}
        >
          <Toolbar
            sx={{
              borderBottomWidth: '1px',
              borderBottomColor: 'grey.300',
              borderBottomStyle: 'solid',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box></Box>
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </Toolbar>
          <Box sx={{ p: 3 }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/estimates" element={<Estimates />} />
              <Route path="/new-estimate" element={<NewEstimate />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </Router>
  );
};

export default Dashboard;
