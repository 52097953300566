import React, { useState } from 'react';
import {
  Container,
  Grid,
  TextField,
  Button,
  IconButton,
  MenuItem,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Divider,
  Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SaveAltRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import DataTable from '../components/DataTable';

const NewEstimate = () => {
  const [lineItems, setLineItems] = useState([{ influencer: '', service: '', quantity: 1, unitPrice: 0 }]);
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<number | null>(null);


  // Example list of influencers (you can replace this with dynamic data)
  const influencers = [
    { id: 1, name: 'Influencer 1' },
    { id: 2, name: 'Influencer 2' },
    { id: 3, name: 'Influencer 3' },
  ];

  // Add a new line item row
  const addLineItem = () => {
    setLineItems([...lineItems, { influencer: '', service: '', quantity: 1, unitPrice: 0 }]);
  };

  // Handle changes in any line item field
  const handleLineItemChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const updatedLineItems = lineItems.map((item, i) => (i === index ? { ...item, [event.target.name]: event.target.value } : item));
    setLineItems(updatedLineItems);
  };

  // Remove a line item row
  const removeLineItem = (index: number) => {
    setLineItems(lineItems.filter((_, i) => i !== index));
  };

  // Open the modal for a specific row
  const handleOpen = (index: number) => {
    setCurrentRow(index); // Track the row that is being edited
    setOpen(true);
  };

  // Close the modal
  const handleClose = () => {
    setOpen(false);
    setCurrentRow(null); // Reset the current row after closing
  };

  // Handle selecting an influencer from the modal
  const handleSelectInfluencer = (name: string) => {
    if (currentRow !== null) {
      const updatedLineItems = lineItems.map((item, index) => (index === currentRow ? { ...item, influencer: name } : item));
      setLineItems(updatedLineItems);
    }
    setOpen(false); // Close the modal after selecting an influencer
  };

  return (
    <Container maxWidth="lg">
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        {/* Back Button */}
        <Button
        component={Link}
  to="/estimates"
          variant="outlined"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => {}} // Go back to the previous page
          sx={{ marginRight: 2 }}
        >
          Orçamentos
        </Button>
        <h1>Criar Orçamento</h1>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveAltRounded />}
          onClick={() => {}} // Go back to the previous page
          sx={{ marginRight: 2 }}
        >
          Salvar
        </Button>
      </Box>

      <Grid container spacing={2}>
        {/* Basic Information */}
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Nome" name="nome" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Number" name="number" defaultValue="OR-001" />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField fullWidth label="Marca" name="marca" placeholder="Nome da marca" />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField label="Descrição" multiline rows={4} variant="outlined" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Button onClick={addLineItem} variant="outlined">
            Add Escopo
          </Button>
          <Divider sx={{ marginVertical: '20px' }} />
          {/* Line Items */}
          {lineItems.map((item, index) => (
            <Grid container spacing={2} key={index}>
              {/* Influencer Select Field */}
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Influencer"
                  name="influencer"
                  value={item.influencer}
                  onClick={() => handleOpen(index)} // Open modal for specific row
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              {/* Escopo */}
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  select
                  label="Escopo"
                  name="service"
                  value={item.service}
                  onChange={(e) => handleLineItemChange(index, e)}
                >
                  <MenuItem value="service1">Combo de Story</MenuItem>
                  <MenuItem value="service2">Story</MenuItem>
                  <MenuItem value="service3">TikTok</MenuItem>
                  <MenuItem value="service4">Reels</MenuItem>
                  <MenuItem value="service5">Inserção de Youtube</MenuItem>
                </TextField>
              </Grid>

              {/* Quantity */}
              <Grid item xs={12} md={1}>
                <TextField fullWidth label="Qtd" type="number" name="quantity" value={item.quantity} onChange={(e) => handleLineItemChange(index, e)} />
              </Grid>

              {/* Unit Price */}
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Valor Unitário"
                  type="number"
                  name="unitPrice"
                  value={item.unitPrice}
                  onChange={(e) => handleLineItemChange(index, e)}
                  InputProps={{ startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
                />
              </Grid>

              {/* Remove Line Item */}
              <Grid item xs={12} md={1}>
                <IconButton onClick={() => removeLineItem(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Influencer Selection Modal */}
      <Dialog open={open} onClose={handleClose} fullScreen>
        <DialogTitle>Selecione um influencer</DialogTitle>
        <Divider sx={{margin:0 }}/>
        <DialogContent>
        <DataTable/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default NewEstimate;
