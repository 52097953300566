import React, { useEffect, useState } from 'react';
import { Typography, Button, Box, Grid, Paper, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Alert } from '@mui/material';
import axios from 'axios';
import ProxyIcon from '@mui/icons-material/Http'; // Example icon for Proxy
import CodeIcon from '@mui/icons-material/Code';
import DataTable from '../components/DataTable';
import { Link } from 'react-router-dom';

interface Container {
  id: number;
  name: string;
  status: string;
}

const Estimates: React.FC = () => {
 

  return (
    <div>
      <Box flexDirection="row" display="flex" justifyContent="space-between" alignItems="center">
     <h2>Orçamentos</h2>
     <Button variant="contained" color="primary"
  component={Link}
  to="/new-estimate">
  Novo Orçamento
</Button>
     </Box>
    </div>
  );
};

export default Estimates;
