import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, Grid, TextField, Snackbar, Alert } from '@mui/material';
import { DataRow } from '../utils/constants';
import { deleteInfluencer, updateInfluencer } from '../services/api';

interface ModalDetailsProps {
  open: boolean;
  onClose: () => void;
  row: DataRow;
  reload: () => void;

}

const ModalDetails: React.FC<ModalDetailsProps> = ({ open, onClose, row, reload }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedRow, setEditedRow] = useState<DataRow>(row);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    setEditedRow(row);
  }, [row]);

  const onCloseLocal = () => {
    setEditMode(false);
    onClose();
  };

  const handleDelete = () => {
    if(editMode && editedRow.id){
      deleteInfluencer(editedRow.id).then((updatedData: any) => {
        if (updatedData) {
          setSnackbarMessage('Influencer deleted successfully');
          setSnackbarSeverity('success');
          setEditMode(!editMode);
          onCloseLocal()
          reload()
        } else {
          setSnackbarMessage('Failed to delete influencer');
          setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
      });
    }
    else{
      setEditMode(!editMode);
    }
  }
  const handleEditToggle = () => {
    if (editMode && editedRow.id) {
      updateInfluencer(editedRow.id, editedRow).then((updatedData: any) => {
        if (updatedData) {
          setSnackbarMessage('Influencer updated successfully');
          setSnackbarSeverity('success');
          setEditMode(!editMode);
        } else {
          setSnackbarMessage('Failed to update influencer');
          setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
      });
    }
    else{
      setEditMode(!editMode);
    }
  };

  const handleFieldChange = (key: keyof DataRow, value: string) => {
    const numericFields: (keyof DataRow)[] = [
      'base_insta',
      'base_tiktok',
      'base_youtube',
      'value_combo_stories',
      'value_reels',
      'value_insert_youtube',
      'value_tiktok',
    ];

    const updatedValue = numericFields.includes(key) ? Number(value) : value;
    setEditedRow({ ...editedRow, [key]: updatedValue });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: 'auto',
            maxWidth: '90%',
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          <Grid container spacing={2}>
            {Object.keys(editedRow).map((key, index) => (
              <Grid
                item
                xs={12}
                sm={4}
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  minWidth: 0,
                  flexBasis: 'auto',
                  wordBreak: 'break-word',
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {key}
                </Typography>
                {editMode ? (
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={editedRow[key as keyof DataRow]}
                    onChange={(e) => handleFieldChange(key as keyof DataRow, e.target.value)}
                    type={typeof editedRow[key as keyof DataRow] === 'number' ? 'number' : 'text'}
                  />
                ) : (
                  <Typography>{editedRow[key as keyof DataRow] || 'N/A'}</Typography>
                )}
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Box>  <Button variant="contained" color="primary" onClick={handleEditToggle}>
              {editMode ? 'Save' : 'Edit'}
            </Button> {editMode &&  <Button variant="contained" color="error" onClick={handleDelete}>
             Deletar
            </Button>}</Box>
           
           
            <Button onClick={onCloseLocal} variant="contained">
              Fechar
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Snackbar for success/error message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ModalDetails;
