// src/keycloak/keycloak.ts

import Keycloak, { KeycloakInstance } from 'keycloak-js';

const keycloakConfig = {
  url: 'https://auth.7virtual.online',
  realm: 'lub',
  clientId: 'lub-dashboard',
};

const keycloak: KeycloakInstance = new Keycloak(keycloakConfig);

export default keycloak;
