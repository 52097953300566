import React, { useState, useEffect } from 'react';
import {
  TextField, Table, TableBody, TableCell, TableHead, TableRow,
  Paper, Checkbox, Box, Button,
  CircularProgress,
  Divider
} from '@mui/material';
import { DataRow } from '../utils/constants';
import ModalDetails from './ModalDetails';
import ColumnToggles from './ColumnToggles';
import SearchForm from './SearchForm';
import { formatDate, formatCurrency } from '../utils/format';
import { columnNames, initialSelectedColumns } from '../utils/constants';
import { searchInfluencers, fetchDropdownOptions, fetchData, fetchMinMaxOptions } from '../services/api';
import LinkIcon from '@mui/icons-material/Link';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';




const DataTable: React.FC = () => {
  const [data, setData] = useState<DataRow[]>([]);
  const [dropdownOptions, setDropdownOptions] = useState<any>(null);
  const [minMaxValues, setMinMaxValues] = useState<any>(null);
  const [selectedColumns, setSelectedColumns] = useState<Record<string, boolean>>(initialSelectedColumns);
  const [search, setSearch] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [openRowModal, setOpenRowModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<DataRow | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showColumnToggles, setShowColumnToggles] = useState(false); // State to toggle ColumnToggles visibility
  

  useEffect(() => {
    loadData();
    loadDropdownOptions();
    loadMinMaxValues();
  }, []);

  const loadData = async (filters: any = {}) => {
    try {
      setLoading(true);
      setError(null);
      const fetchedData = await searchInfluencers(filters); // Call searchInfluencers for initial load as well
      setData(fetchedData);
    } catch (error) {
      setError('Failed to load data.');
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadDropdownOptions = async () => {
    try {
      const options = await fetchDropdownOptions(); // Use fetchDropdownOptions from api.ts
      setDropdownOptions(options);
    } catch (error) {
      setError('Failed to load dropdown options.');
      console.error('Error fetching dropdown options:', error);
    }
  };

  const loadMinMaxValues = async () => {
    try {
      const options = await fetchMinMaxOptions();
      setMinMaxValues(options);
    } catch (error) {
      setError('Failed to load min max values.');
      console.error('Error fetching load min max values:', error);
    }
  }

  const handleSearch = async (filters: any) => {
    loadData(filters);
  };

  const handleReset = () => {
    setSearch('');
    setSelectedRows(new Set());
    loadData(); // Reload data without filters
  };

  const handleRowSelect = (index: number) => {
    const newSelectedRows = new Set(selectedRows);
    newSelectedRows.has(index) ? newSelectedRows.delete(index) : newSelectedRows.add(index);
    setSelectedRows(newSelectedRows);
  };

  const handleOpenRowModal = (row: DataRow) => {
    console.log(row)
    setSelectedRow(null)
    setSelectedRow(row);
    setOpenRowModal(true);
  };
  const handleToggleFilters = () => {
    setShowColumnToggles((prevState) => !prevState); // Toggle the visibility of ColumnToggles
  };
  const filteredData = (data && data.length > 0) ? data.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        (typeof value === 'string' || Array.isArray(value)) &&
        value.toString().toLowerCase().includes(search.toLowerCase())
    )
  ) : [];

  return (
    <Box>
      {dropdownOptions && (
        <SearchForm 
          onSearch={handleSearch} 
          onReset={handleReset} 
          dropdownOptions={dropdownOptions} 
          minMaxValues={minMaxValues}
          handleToggleFilters={handleToggleFilters}
        />
      )}


      {showColumnToggles && (
        <Box sx={{ mt: 2 }}>
          <ColumnToggles
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </Box>
      )}

      {loading ? (
        <Box justifyContent={'center'}><CircularProgress /></Box>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Paper sx={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox />
                </TableCell>
                {Object.keys(selectedColumns).map(
                  (column) =>
                    selectedColumns[column] && <TableCell key={column}>{columnNames[column]}</TableCell>
                )}
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selectedRows.has(index)}
                      onChange={() => handleRowSelect(index)}
                    />
                  </TableCell>
                  {Object.keys(selectedColumns).map(
                    (column) =>
                      selectedColumns[column] && (
                        <TableCell key={column}>
                           {column === 'data' ? (
                            formatDate(row[column as keyof DataRow] as string)
                          ) : column.startsWith('value_') ? (
                            formatCurrency(row[column as keyof DataRow] as number)
                          ) : column === 'instagram' && row[column as keyof DataRow] ? (
                            <a href={row[column as keyof DataRow] as string} target="_blank" rel="noopener noreferrer">
                              <InstagramIcon />
                            </a>
                          ) : column === 'youtube' && row[column as keyof DataRow] ? (
                            <a href={row[column as keyof DataRow] as string} target="_blank" rel="noopener noreferrer">
                              <YouTubeIcon />
                            </a>
                          ) : column === 'tiktok' && row[column as keyof DataRow] ? (
                            <a href={row[column as keyof DataRow] as string} target="_blank" rel="noopener noreferrer">
                              <LinkIcon />
                            </a>
                          ) : Array.isArray(row[column as keyof DataRow]) ? (
                            (row[column as keyof DataRow] as string[]).join(', ')
                          ) : (
                            row[column as keyof DataRow] || '-'
                          )}
                        </TableCell>
                      )
                  )}
                  <TableCell>
                    <Button variant="contained" onClick={() => handleOpenRowModal(row)}>
                      Detalhes
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}

      {selectedRow && (
        <ModalDetails open={openRowModal} onClose={() => setOpenRowModal(false)} row={selectedRow} reload={loadData} />
      )}
    </Box>
  );
};

export default DataTable;
