import React, { useState, useEffect } from 'react';
import Dashboard from './components/Dashboard';
import { ThemeProvider, CssBaseline, Box, CircularProgress } from '@mui/material';
import theme from './theme';
import Keycloak from 'keycloak-js';
import keycloak from './services/keycloak';


const KEYCLOAK_ENABLED = process.env.REACT_APP_API_BASE_URL ? undefined : process.env.REACT_APP_API_BASE_URL || true;

const App: React.FC = () => {
  const [keycloakInitialized, setKeycloakInitialized] = useState(false);

  useEffect(() => {
    if (KEYCLOAK_ENABLED) {
    keycloak.init({ onLoad: 'login-required' }).then((authenticated) => {
      if (authenticated) {
        setKeycloakInitialized(true);
      } else {
        keycloak.login();
      }
    });
  }
  else {
    setKeycloakInitialized(true);
  }
  }, []);

  if (!keycloakInitialized) {
    return  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100vh"
  >
    <CircularProgress />
  </Box>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Dashboard />
    </ThemeProvider>
  );
};

export default App;
