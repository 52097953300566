export interface DataRow {
    id: string;
    data: string;
    nome: string;
    segmento: string[];
    instagram: string;
    youtube: string;
    base_insta: number;
    base_tiktok: number;
    base_youtube: number;
    value_combo_stories: number;
    value_reels: number;
    value_insert_youtube: number;
    value_tiktok: number;
    cidade: string;
    estado: string;
    aposta: string;
    contato: string;
    email: string;
    agencia: string;
  }
  
  export const columnNames: Record<string, string> = {
    id: "ID",
    data: "Date",
    nome: "Name",
    segmento: "Segment",
    instagram: "Instagram",
    youtube: "YouTube",
    base_insta: "Instagram Base",
    base_tiktok: "TikTok Base",
    base_youtube: "YouTube Base",
    value_combo_stories: "Combo Stories Value",
    value_reels: "Reels Value",
    value_insert_youtube: "YouTube Insert Value",
    value_tiktok: "TikTok Value",
    cidade: "City",
    estado: "State",
    aposta: "Bet",
    contato: "Contact",
    email: "Email",
    agencia: "Agency",
  };
  
  export const initialSelectedColumns = {
    id: false,
    data: false,
    nome: true,
    segmento: true,
    instagram: true,
    youtube: true,
    base_insta: false,
    base_tiktok: false,
    base_youtube: false,
    value_combo_stories: true,
    value_reels: true,
    value_insert_youtube: false,
    value_tiktok: true,
    cidade: true,
    estado: true,
    aposta: false,
    contato: false,
    email: true,
    agencia: false,
  };