import React, { useEffect, useState } from 'react';
import { Typography, Button, Box, Grid, Paper, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Alert } from '@mui/material';
import axios from 'axios';
import ProxyIcon from '@mui/icons-material/Http'; // Example icon for Proxy
import CodeIcon from '@mui/icons-material/Code';
import DataTable from '../components/DataTable';

interface Container {
  id: number;
  name: string;
  status: string;
}

const Home: React.FC = () => {
 

  return (
    <div>
     <h2>Influencers</h2>
     <DataTable/>
    </div>
  );
};

export default Home;
